<template>
  <div class="mt-5">
    <br />
    <br />
    <br />
    <div class="row justify-content-end">
      <div class="col-auto ">
        <button @click="refresh()" class="btn ms-auto btn-primary">
          <i class="bi bi-arrow-clockwise"></i>
        </button>
      </div>
      <div class="col-auto ">
        <button @click="archiveOldBookings()" class="btn ms-auto btn-primary">
          <i class="bi bi-archive-fill"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-3 my-3">
        <div @click="$router.push({
          name: 'reservations-details',
          params: { reference: nextReservation.reference }
        })" class="rounded shadow p-2 d-flex justify-content-between "
          :class="nextReservation.isCurrent ? 'border border-success' : ''">
          <span class="w-15 fw-bold">
            {{ nextReservation.startTime }}
            <br>
            {{ nextReservation.endTime }}
          </span>
          <span class="fw-bold text-primary w-65">
            {{ nextReservation.fullName }}
            <br>
            <span class="bg-primary text-white rounded  px-2">
              {{ nextReservation.price }}
            </span>
          </span>
          <span class="w-25 ">
            <div class="d-flex justify-content-center">
              <span v-if="nextReservation.paid == 1">
                <i class="bi bi-check2-all text-success"></i>
              </span>
              <span v-else>
                <i class="bi bi-exclamation-triangle-fill text-danger"></i>
              </span>
              <span v-if="nextReservation.paid && nextReservation.is_subscription">
                <i class="bi bi-wallet-fill text-primary"></i>
              </span>
              <span v-else-if="nextReservation.paid && !nextReservation.is_subscription">
                <i class="bi bi-cash-stack text-primary"></i>
              </span>
              <span v-if="nextReservation.user">
                <i class="bi bi-pc-display text-primary"></i>
              </span>
              <span v-else>
                <i class="bi bi-phone-flip text-primary"></i>
              </span>
            </div>
            <div class="text-uppercase">
              {{ nextReservation.date | date }}
            </div>
          </span>
        </div>
      </div>

      <div v-for="(reservation, index) in nextReservations" :key="index++" class="col-xl-3 my-3">
        <div @click="$router.push({
          name: 'reservations-details',
          params: { reference: reservation.reference }
        })" class="rounded shadow p-2 d-flex justify-content-between  "
          :class="reservation.isCurrent ? 'border border-success' : ''">
          <span class="w-15 fw-bold">
            {{ reservation.startTime }}
            <br>
            {{ reservation.endTime }}
          </span>
          <span class="fw-bold text-primary w-65">
            {{ reservation.fullName }}
            <br>
            <span class="bg-primary text-white rounded  px-2">
              {{ reservation.price }}
            </span>
          </span>
          <span class="w-25 ">
            <div class="d-flex justify-content-center">
              <span v-if="reservation.paid == 1">
                <i class="bi bi-check2-all text-success"></i>
              </span>
              <span v-else>
                <i class="bi bi-exclamation-triangle-fill text-danger"></i>
              </span>
              <span v-if="reservation.paid && reservation.is_subscription">
                <i class="bi bi-wallet-fill text-primary"></i>
              </span>
              <span v-else-if="reservation.paid && !reservation.is_subscription">
                <i class="bi bi-cash-stack text-primary"></i>
              </span>
              <span v-if="reservation.user">
                <i class="bi bi-pc-display text-primary"></i>
              </span>
              <span v-else>
                <i class="bi bi-phone-flip text-primary"></i>
              </span>
            </div>
            <div class="text-uppercase">
              {{ reservation.date | date }}
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="(activeField, index) in activeFields" :key="index++" class="col-xl-2 col-6 my-3">
        <div @click="$router.push({
          name: 'customers-details',
          params: { reference: activeField.reference }
        })" class="rounded shadow p-2 d-flex justify-content-between align-items-center ">
          <span>
            {{ activeField.fullName }}
          </span>

          <span v-if="activeField.minutesSinceLastSeen <= 1" class="  ">
            <i class="bi bi-dot fs-1 text-success" style="font-size: 40px !important;"></i>
          </span>
          <span v-else>
            {{ activeField.minutesSinceLastSeen }}
          </span>

        </div>
      </div>
    </div>
    <DashboardTotals :totals="dashboardTotals" />
    <br>
    <div class="row">
      <div class="col-xl-12">
        <DataTable :headers="headers" :data="nextPayments" :items-per-page="5"
          :summable-columns="['nextPaymentAmount', 'subscriptionAmountToPay', 'bookingPrice']" />
      </div>
      <!-- <div v-for="(nextPayment, index) in nextPayments" :key="index++" class="col-xl-6">
          {{ nextPayment.nextPaymentDate }}
          {{ nextPayment.nextPaymentAmount }}
          {{ nextPayment.fieldFullName }}
          {{ nextPayment.subscriptionAmountToPay }}
          {{ nextPayment.bookingPrice }}
      </div> -->
    </div>
    <!-- {{nextPayments}} -->

    <div class="row">

      <div class="col-4">
        <div v-for="(historicalPayment, index) in historicalPayments" :key="index++" class=" my-3">
          <div class="rounded shadow p-2 d-flex justify-content-between align-items-center ">
            <span>
              {{ historicalPayment.date }}
            </span>

            
            <span >
              {{ historicalPayment.totalAmountPaid }}
            </span>

          </div>
        </div>
      </div>

      <div class="col-4">
        <div v-for="(monthlyPayment, index) in monthlyPayments" :key="index++" class=" my-3">
          <div class="rounded shadow p-2 d-flex justify-content-between align-items-center ">
            <span>
              {{ monthlyPayment.month }}
            </span>

            
            <span >
              {{ monthlyPayment.totalAmountPaid }}
            </span>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import moment from "moment";
import DashboardTotals from "../components/cards/DashboardTotals.vue";
import DataTable from "../components/cards/DataTable.vue";
export default {
  components: {
    DashboardTotals,
    DataTable
  },
  data() {
    return {
      headers: [
        { field: 'fieldFullName', label: 'Field Name' },
        { field: 'nextPaymentAmount', label: 'Amount' },
        { field: 'nextPaymentDate', label: 'Payment Date' },
        { field: 'subscriptionAmountToPay', label: 'Subscription Amount' },
        { field: 'bookingPrice', label: 'Booking Price' }
      ],


    };
  },
  computed: {
    ...mapGetters('dashboard', {
      nextReservation: 'getNextReservation',
      nextReservations: 'getNextReservations',
      activeFields: 'getActiveFields',
      dashboardTotals: 'getDashboardTotals',
      nextPayments: 'getNextPayments',
      historicalPayments: 'getHistoricalPayments',
      monthlyPayments: 'getMonthlyPayments',
    }),
  },
  async mounted() {
    this.$store.dispatch("dashboard/getNextReservation");
    this.$store.dispatch("dashboard/getNextReservations");
    this.$store.dispatch("dashboard/getActiveFields");
    this.$store.dispatch("dashboard/getDashboardTotals");
    this.$store.dispatch("dashboard/getNextPayments");
    this.$store.dispatch("dashboard/getHistPaymentsByDate");
    this.$store.dispatch("dashboard/getPaymentsByMonth");
  },
  methods: {
    async refresh() {
      this.$store.dispatch("dashboard/getNextReservation");
      this.$store.dispatch("dashboard/getNextReservations");
      this.$store.dispatch("dashboard/getActiveFields");
      this.$store.dispatch("dashboard/getDashboardTotals");
      this.$store.dispatch("dashboard/getNextPayments");
      this.$store.dispatch("dashboard/getHistPaymentsByDate");
      this.$store.dispatch("dashboard/getPaymentsByMonth");
    },
    async archiveOldBookings() {
      await this.$store.dispatch("dashboard/archiveOldBookings");
      await this.refresh();
    },
  },
  filters: {
    date(value) {
      if (!value)
        return null;
      const date = new Date(value);
      const dayName = date.toLocaleString('fr-FR', { weekday: 'long' }).slice(0, 3);
      const monthName = date.toLocaleString('fr-FR', { month: 'long' }).slice(0, 3);
      const dayNumber = date.getDate();
      return `${dayName} ${dayNumber} ${monthName}`;
    }
  }
};
</script>
