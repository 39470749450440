var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('br'),_c('br'),_c('br'),_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn ms-auto btn-primary",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-arrow-clockwise"})])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn ms-auto btn-primary",on:{"click":function($event){return _vm.archiveOldBookings()}}},[_c('i',{staticClass:"bi bi-archive-fill"})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 my-3"},[_c('div',{staticClass:"rounded shadow p-2 d-flex justify-content-between",class:_vm.nextReservation.isCurrent ? 'border border-success' : '',on:{"click":function($event){return _vm.$router.push({
        name: 'reservations-details',
        params: { reference: _vm.nextReservation.reference }
      })}}},[_c('span',{staticClass:"w-15 fw-bold"},[_vm._v(" "+_vm._s(_vm.nextReservation.startTime)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.nextReservation.endTime)+" ")]),_c('span',{staticClass:"fw-bold text-primary w-65"},[_vm._v(" "+_vm._s(_vm.nextReservation.fullName)+" "),_c('br'),_c('span',{staticClass:"bg-primary text-white rounded px-2"},[_vm._v(" "+_vm._s(_vm.nextReservation.price)+" ")])]),_c('span',{staticClass:"w-25"},[_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.nextReservation.paid == 1)?_c('span',[_c('i',{staticClass:"bi bi-check2-all text-success"})]):_c('span',[_c('i',{staticClass:"bi bi-exclamation-triangle-fill text-danger"})]),(_vm.nextReservation.paid && _vm.nextReservation.is_subscription)?_c('span',[_c('i',{staticClass:"bi bi-wallet-fill text-primary"})]):(_vm.nextReservation.paid && !_vm.nextReservation.is_subscription)?_c('span',[_c('i',{staticClass:"bi bi-cash-stack text-primary"})]):_vm._e(),(_vm.nextReservation.user)?_c('span',[_c('i',{staticClass:"bi bi-pc-display text-primary"})]):_c('span',[_c('i',{staticClass:"bi bi-phone-flip text-primary"})])]),_c('div',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.nextReservation.date))+" ")])])])]),_vm._l((_vm.nextReservations),function(reservation,index){return _c('div',{key:index++,staticClass:"col-xl-3 my-3"},[_c('div',{staticClass:"rounded shadow p-2 d-flex justify-content-between",class:reservation.isCurrent ? 'border border-success' : '',on:{"click":function($event){return _vm.$router.push({
        name: 'reservations-details',
        params: { reference: reservation.reference }
      })}}},[_c('span',{staticClass:"w-15 fw-bold"},[_vm._v(" "+_vm._s(reservation.startTime)+" "),_c('br'),_vm._v(" "+_vm._s(reservation.endTime)+" ")]),_c('span',{staticClass:"fw-bold text-primary w-65"},[_vm._v(" "+_vm._s(reservation.fullName)+" "),_c('br'),_c('span',{staticClass:"bg-primary text-white rounded px-2"},[_vm._v(" "+_vm._s(reservation.price)+" ")])]),_c('span',{staticClass:"w-25"},[_c('div',{staticClass:"d-flex justify-content-center"},[(reservation.paid == 1)?_c('span',[_c('i',{staticClass:"bi bi-check2-all text-success"})]):_c('span',[_c('i',{staticClass:"bi bi-exclamation-triangle-fill text-danger"})]),(reservation.paid && reservation.is_subscription)?_c('span',[_c('i',{staticClass:"bi bi-wallet-fill text-primary"})]):(reservation.paid && !reservation.is_subscription)?_c('span',[_c('i',{staticClass:"bi bi-cash-stack text-primary"})]):_vm._e(),(reservation.user)?_c('span',[_c('i',{staticClass:"bi bi-pc-display text-primary"})]):_c('span',[_c('i',{staticClass:"bi bi-phone-flip text-primary"})])]),_c('div',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("date")(reservation.date))+" ")])])])])})],2),_c('div',{staticClass:"row"},_vm._l((_vm.activeFields),function(activeField,index){return _c('div',{key:index++,staticClass:"col-xl-2 col-6 my-3"},[_c('div',{staticClass:"rounded shadow p-2 d-flex justify-content-between align-items-center",on:{"click":function($event){return _vm.$router.push({
        name: 'customers-details',
        params: { reference: activeField.reference }
      })}}},[_c('span',[_vm._v(" "+_vm._s(activeField.fullName)+" ")]),(activeField.minutesSinceLastSeen <= 1)?_c('span',{staticClass:""},[_c('i',{staticClass:"bi bi-dot fs-1 text-success",staticStyle:{"font-size":"40px !important"}})]):_c('span',[_vm._v(" "+_vm._s(activeField.minutesSinceLastSeen)+" ")])])])}),0),_c('DashboardTotals',{attrs:{"totals":_vm.dashboardTotals}}),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('DataTable',{attrs:{"headers":_vm.headers,"data":_vm.nextPayments,"items-per-page":5,"summable-columns":['nextPaymentAmount', 'subscriptionAmountToPay', 'bookingPrice']}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},_vm._l((_vm.historicalPayments),function(historicalPayment,index){return _c('div',{key:index++,staticClass:"my-3"},[_c('div',{staticClass:"rounded shadow p-2 d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(historicalPayment.date)+" ")]),_c('span',[_vm._v(" "+_vm._s(historicalPayment.totalAmountPaid)+" ")])])])}),0),_c('div',{staticClass:"col-4"},_vm._l((_vm.monthlyPayments),function(monthlyPayment,index){return _c('div',{key:index++,staticClass:"my-3"},[_c('div',{staticClass:"rounded shadow p-2 d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(monthlyPayment.month)+" ")]),_c('span',[_vm._v(" "+_vm._s(monthlyPayment.totalAmountPaid)+" ")])])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }