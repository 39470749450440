<template>
  <div class="row">
    <div  class="col-xl-3 col-md-4 col-sm-6 my-3 ">
      <div class="shadow rounded">

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span class="">
            Total des Abonnements
          </span>
          <span class="fw-bold">
            {{ totals.total_subscriptions }}
          </span>
        </div>

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span class="">
            Total des Clients
          </span>
          <span class="fw-bold">
            {{ totals.total_fields }}
          </span>
        </div>

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span class="">
            Total Active Clients
          </span>
          <span class="fw-bold">
            {{ totals.total_active_fields }}
          </span>
        </div>

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span class="">
            Total des Paiements
          </span>
          <span class="fw-bold">
            {{ totals.total_payments }}
          </span>
        </div>

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span class="">
            Total des Paiements du mois
          </span>
          <span class="fw-bold">
            {{ totals.total_month_payments }}
          </span>
        </div>

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span class="">
            Total des Paiements du jour
          </span>
          <span class="fw-bold">
            {{ totals.total_payment_today }}
          </span>
        </div>

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span class="">
            Total des Matchs
          </span>
          <span class="fw-bold">
            {{ totals.total_matches }}
          </span>
        </div>
      </div>
    </div>
    <div  class="col-xl-3 col-md-4 col-sm-6 my-3 ">
      <div class="shadow rounded">
        <div class="p-3 d-flex justify-content-between align-items-center">
          <span>Réservations Aujourd'hui</span>
          <span class="fw-bold">{{ totals.bookings_today }}</span>
        </div>

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span>Réservations Futures</span>
          <span class="fw-bold">{{ totals.future_bookings }}</span>
        </div>

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span>Réservations par Abonnement Aujourd'hui</span>
          <span class="fw-bold">{{ totals.bookings_by_subscription_today }}</span>
        </div>

        <div class="p-3 d-flex justify-content-between align-items-center">
          <span>Réservations Futures par Abonnement</span>
          <span class="fw-bold">{{ totals.future_bookings_by_subscription }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totals: {
      type: Object,
      required: true 
    }
  },
  data() {
    return {
      frenchTitles: {
        // total_fields: "Total des Terrains",
        // total_payments: "Total des Paiements",
        // total_subscriptions: "Total des Abonnements",
        // total_bookings: "Total des Réservations",
        total_active_fields: "Terrains Actifs",
        total_verified_fields: "Terrains Vérifiés",
        total_unverified_fields: "Terrains Non Vérifiés",
        // total_matches: "Total des Matchs",
        total_terrains: "Total des Terrains",
        percentage_verified_fields: "% de Terrains Vérifiés",
        percentage_active_fields: "% de Terrains Actifs",
        average_payment: "Paiement Moyen",
        total_revenue: "Revenu Total",
        current_month_bookings: "Réservations du Mois",
        current_month_subscriptions: "Abonnements du Mois"
      }
    };
  },
  computed: {
    formattedTotals() {
      const formatted = {};
      for (const [key, value] of Object.entries(this.totals)) {
        if (key.includes('percentage')) {
          formatted[key] = `${value}%`;
        } else if (key.includes('payment') || key.includes('revenue')) {
          formatted[key] = `${value} MAD`;
        } else {
          formatted[key] = value;
        }
      }
      return formatted;
    }
  },
  methods: {
    formatKey(key) {
      return this.frenchTitles[key] || key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
  }
};
</script>

<style scoped>
.rounded {
  transition: all 0.3s ease;
}
.rounded:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}
</style>